<template>
  <v-container id="dashboard" fluid tag="section">

  <div class="mb-12"/>

  <base-material-card icon="mdi-file-download">
    <slot slot="title">Downloads</slot>
    <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
      <div class="d-flex">
        <span class="small mr-2">Start Date</span> 
        <datetime input-id="start" class="mb-5" input-style="border-bottom:1px solid" v-model="start" type="datetime" :max-datetime="end"/>
        <v-icon class="small mt-n4 mr-3" @click="start = null">mdi-close-circle</v-icon>
      </div> 
      <div class="d-flex">
        <span class="small mr-2"> End Date </span>
        <datetime input-id="end" class="mb-5" input-style="border-bottom:1px solid" v-model="end" type="datetime" :min-datetime="start"></datetime>
        <v-icon class="small mt-n4 mr-3" @click="end = null">mdi-close-circle</v-icon>
      </div> 
    </div>
    <div>
      <v-btn class="mb-2" color="primary" @click='downloadFile("Event/doorlist", "pdf", event.id)'>Doorlist</v-btn>
      <v-btn class="mb-2" color="primary" @click='downloadFile("Event/ticketReport", "csv", event.id)'>Ticket Report</v-btn>
      <v-btn class="mb-2" color="primary" @click='downloadFile("BoxOffice/orderReport", "csv", boxOffice.id)'>Order Report</v-btn>
      <v-btn class="mb-2" color="primary" @click='downloadFile("BoxOffice/productReport", "csv", boxOffice.id)'>Product Report</v-btn>
    </div>
  </base-material-card>

  <base-material-card icon="mdi-chart-line">
    <slot slot="title">Sales Summary</slot>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="2" class="text-right"><span v-if="$vuetify.breakpoint.smAndUp">Ticket </span>Sales</v-col>
      <v-col cols="2" class="text-right">Total <span v-if="$vuetify.breakpoint.smAndUp">Tickets</span></v-col>
      <v-col cols="2" class="text-right"><span v-if="$vuetify.breakpoint.smAndUp">Percentage Sold</span><span v-else>%</span></v-col>
      <v-col cols="2" class="text-right"><span v-if="$vuetify.breakpoint.smAndUp">Revenue</span><span v-else>Rev</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="4">Total</v-col>
      <v-col cols="2" class="text-right">{{allSales.ticketsSold}}</v-col>
      <v-col cols="2" class="text-right">{{allSales.totalTickets}}</v-col>
      <v-col cols="2" class="text-right">{{utils.formatPercent(allSales.ticketsSold/allSales.totalTickets)}}</v-col>
      <v-col cols="2" class="text-right">{{utils.currencyDisplay(allSales.revenue)}}</v-col>
    </v-row>
    <v-row>
      <v-col cols="4">Last 24 Hours</v-col>
      <v-col cols="2" class="text-right">{{twentyFourHourSales.ticketsSold}}</v-col>
      <v-col cols="2" class="text-right">{{twentyFourHourSales.totalTickets}}</v-col>
      <v-col cols="2" class="text-right">{{utils.formatPercent(twentyFourHourSales.ticketsSold/twentyFourHourSales.totalTickets)}}</v-col>
      <v-col cols="2" class="text-right">{{utils.currencyDisplay(twentyFourHourSales.revenue)}}</v-col>
    </v-row>
    <v-row>
      <v-col cols="4">Last 7 Days</v-col>
      <v-col cols="2" class="text-right">{{weekSales.ticketsSold}}</v-col>
      <v-col cols="2" class="text-right">{{weekSales.totalTickets}}</v-col>
      <v-col cols="2" class="text-right">{{utils.formatPercent(weekSales.ticketsSold/weekSales.totalTickets)}}</v-col>
      <v-col cols="2" class="text-right">{{utils.currencyDisplay(weekSales.revenue)}}</v-col>
    </v-row>
    <v-row>
      <v-col cols="4">Last 30 Days</v-col>
      <v-col cols="2" class="text-right">{{monthSales.ticketsSold}}</v-col>
      <v-col cols="2" class="text-right">{{monthSales.totalTickets}}</v-col>
      <v-col cols="2" class="text-right">{{utils.formatPercent(monthSales.ticketsSold/monthSales.totalTickets)}}</v-col>
      <v-col cols="2" class="text-right">{{utils.currencyDisplay(monthSales.revenue)}}</v-col>
    </v-row>
  </base-material-card>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import axios from "axios";
  import Store from '@/services/Store.js';

  export default {
    name: 'Event',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
    },  

    data () {
      return {
        start: null,
        end: null,
        event: {},
        boxOffice: {},
        allSales: {},
        twentyFourHourSales: {},
        weekSales: {},
        monthSales: {},
        user: {},
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.event.id = this.$route.params.id;
      this.load();
    },

    methods: {

      async load() {
        this.event = await Api.post(this, "Event", "read", this.event);
        this.readBoxOffice();
        this.readSalesReport();
      },

      async readBoxOffice() {
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
      },

      async readSalesReport() {
        this.allSales.type = "event";
        this.allSales.id  = this.event.id;
        this.allSales = await Api.post(this, "SalesReport", "read", this.allSales)
        let oneDay = 24 * 60 * 60 * 1000;
        this.twentyFourHourSales.type = "event";
        this.twentyFourHourSales.id  = this.event.id;
        this.twentyFourHourSales.startDate = new Date().getTime() - oneDay;
        this.twentyFourHourSales = await Api.post(this, "SalesReport", "read", this.twentyFourHourSales);
        this.weekSales.id  = this.event.id;
        this.weekSales.type = "event";
        this.weekSales.startDate = new Date().getTime() - oneDay * 7;
        this.weekSales = await Api.post(this, "SalesReport", "read", this.weekSales);
        this.monthSales.id = this.event.id;
        this.monthSales.type = "event";
        this.monthSales.startDate = new Date().getTime() - oneDay * 30;
        this.monthSales = await Api.post(this, "SalesReport", "read", this.monthSales);
      },

      async downloadFile(type, extension, id) {
        var fileDownload = require('js-file-download');
        var config = {};
        config.responseType = 'blob';
        config.headers = {};
        var payload = {};
        payload.id = id;
        payload.start = Utils.dateToDateTime(this.start);
        payload.end = Utils.dateToDateTime(this.end);
        const accessToken = await this.$auth.getTokenSilently();
        config.headers.Authorization =  `Bearer ${accessToken}`;
        const response = await axios.post(process.env.VUE_APP_API_URL + "/" + type, payload, config);
        fileDownload(response.data, id + "_" + type + "." + extension);
      },
    }
  }
</script>
